<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and legend -->
      <b-card-title class="mb-50">
        Attendee Answers
      </b-card-title>
      <!--/ title and legend -->

      <!-- datepicker -->
      
    <!-- datepicker -->
    </b-card-header>

    <b-card-body>
    
    <b-table
      responsive="sm"
      :items="items"
        :fields="tableColumns" 
     
        show-empty
        empty-text="No matching records found"
        
    >
    <template #cell(TAG)="data">
          <b-media vertical-align="center">
            <template #aside>
             
            </template>
           
              {{ data.item.tag }} 
              
           </b-media>
        </template>
        

        <template #cell(1)="data">
          <b-media vertical-align="center">
            <template #aside>
             
            </template>
           
              {{ calculateData(data.item,1) }} 
              
          </b-media>
        </template>

        <template #cell(2)="data">
          <b-media vertical-align="center">
            <template #aside>
             
            </template>
           
            {{ calculateData(data.item,2) }} 
              
         
           </b-media>
        </template>

        <template #cell(3)="data">
          <b-media vertical-align="center">
            <template #aside>
             
            </template>
           
            {{ calculateData(data.item,3) }} 
              
          </b-media>
        </template>
        <template #cell(4)="data">
          <b-media vertical-align="center">
            <template #aside>
             
            </template>
           
            {{ calculateData(data.item,4) }} 
              
         </b-media>
        </template>

        <template #cell(5)="data">
          <b-media vertical-align="center">
            <template #aside>
             
            </template>
           
            {{ calculateData(data.item,5) }} 
              
        </b-media>
        </template>
        <template #cell(6)="data">
          <b-media vertical-align="center">
            <template #aside>
             
            </template>
           
            {{ calculateData(data.item,6) }} 
              
          </b-media>
        </template>
        <template #cell(7)="data">
          <b-media vertical-align="center">
            <template #aside>
             
            </template>
           
            {{ calculateData(data.item,7) }} 
              
           </b-media>
        </template>
        <template #cell(8)="data">
          <b-media vertical-align="center">
            <template #aside>
             
            </template>
           
            {{ calculateData(data.item,8) }} 
              
         
            
          </b-media>
        </template>
        <template #cell(9)="data">
          <b-media vertical-align="center">
            <template #aside>
             
            </template>
           
            {{ calculateData(data.item,9) }} 
              
         </b-media>
        </template>
        <template #cell(10)="data">
          <b-media vertical-align="center">
            <template #aside>
             
            </template>
           
            {{ calculateData(data.item,10) }} 
              
         </b-media>
        </template>


    </b-table>

     
  
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BTable,BCard, BCardBody, BCardHeader, BCardTitle,BMedia,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import apexChatData from './apexChartData'

export default {
  components: {
    BCardCode,
    BMedia,
    BTable,
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    flatPickr,
    BCardTitle,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
     
    const calculateData= (tag,column) =>{
      console.log(tag);
      console.log("Tag:", tag.tag);
     var incomingTag=tag.tag;
    var names=tag.names;
    var info=names[column-1];
   
    var rateInfo=tag.nameGroup[info];
    var result = rateInfo.filter(obj => {
  return obj.questionTag === incomingTag
})
var answer=result[0];
      console.log( "incoming column", column);
      return answer.attendeeAnswer;

    }
    return {
      calculateData
    }
  },
  data() {
    const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series3: '#a4f8cd',
    series2: '#60f2ca',
    series1: '#2bdac7',
  },
}
console.log("data");
console.log(this.data);

 

    
    var values=this.data.map(a => a.attendetitle);
      var tags=this.data.map(a => a.questionTag);
         console.log(values);
         console.log(tags);

         var groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
var seriBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
function getFields(input, field) {
    var output = [];
    for (var i=0; i < input.length ; ++i)
        output.push(input[i][field]);
    return output;
}

   var nameGroup = groupBy(this.data, 'attendetitle');
console.log(nameGroup);
  var tagGroup=groupBy(this.data, 'questionTag');
console.log("TAAGGSS ",tagGroup)
    var seriGroup = [];
    for (var key in nameGroup) {

      var results=getFields(nameGroup[key],"attendeeAnswer");
      console.log("RESULTS");
      console.log(results);
      seriGroup.push({name:key,data: results});
    };
    console.log("Seri Gorup");
    console.log(seriGroup);
    var names=getFields(seriGroup,"name");
    console.log("names",names);
console.log("Seri count",seriGroup.length);
var tableColumns = [
   {key:"TAG"}
  ];
var tableItems=[];

  for (var key in tagGroup) {

var results=tagGroup[key];
console.log("TAGFOREACH");
console.log(results);
tableItems.push({tag:key,data: results,names:names, nameGroup:nameGroup});
};



for (var i=0; i < names.length ; ++i)
tableColumns.push({key:(i+1).toString() , label: "Attendee "+(i+1)});



    return {
      names:names,
      items:tableItems,
     tableColumns:tableColumns,
      apexChatData,
    
      columnChart: {
    series: seriGroup,
    chartOptions: {
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
    //  colors: [chartColors.column.series1, chartColors.column.series2],
      plotOptions: {
        bar: {
          columnWidth: '15%',
          colors: {
            backgroundBarColors: [
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
            ],
            backgroundBarRadius: 10,
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: true,
        position: 'top',
        fontSize: '14px',
        fontFamily: 'Montserrat',
        horizontalAlign: 'left',
      },
      stroke: {
        show: true,
        colors: ['transparent'],
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories:names,
      },
      yaxis: {
        // opposite: isRtl,
        
      },
      fill: {
        opacity: 1,
      },
    },
  },
    }
  },
}
</script>
